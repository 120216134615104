import setState from '@/utilities/store'
import {api} from '@/bootstrap/api'
import mapOrder from '@/utilities/mapOrder'
import {getSorts, SortOptionType} from '@/utilities/ordersSortOptions'
import i18n from '@/plugins/i18n'
import {ActionContext} from 'vuex'
import {StateInterface} from '@/bootstrap/store'
import {LocaleMessage} from 'vue-i18n'
import axios, {AxiosError} from 'axios'
import NotificationTypes from '@/enums/NotificationTypes'

export enum TabGroupEnum {
    ready = 'ready',
    pending = 'pending',
}

export type GetAllocatedOrdersRequestParams = {
    filter_huboo_box: string | null,
    filter_work_group: string | null,
    filter_carrier: string | null,
    hub_id: number | null,
    page: number | null,
    filter_sku: string[],
    filter_sku_type: string|null,
    // Allows adding sort keys, this is dirty, but requires refactor to avoid this
    [key: string]: boolean | number | string | string[] | null,
}

export interface OrdersStateInterface {
    courierFilter: null | string,
    courierFilters: Array<object>,
    customerFilter: null | string,
    customerFilters: Array<object>,
    activeTabGroup: TabGroupEnum,
    activeTabGroupTotal: number,
    readyTabTotal: number,
    pendingTabTotal: number,
    inStockFilter: null | boolean,
    orderTypeFilter: null | string,
    orderTypeFilters: Array<object>,
    orders: Array<object>,
    skuFilterType: string|null,
    skuFilters: Array<string>
    page: number,
    perPage: number
    sort: null | string,
    startPickFromBarcode: Array<number>,
    total: number
}

export interface OrdersGettersInterface {
    getSortName(state: OrdersStateInterface): LocaleMessage | undefined | null,

    getFilterAndSortParams(
        state: OrdersStateInterface,
        getters: OrdersGettersInterface
    ): GetAllocatedOrdersRequestParams,
}

const defaultState = (): OrdersStateInterface => ({
    courierFilter: null,
    courierFilters: [],
    customerFilter: null,
    customerFilters: [],
    activeTabGroup: TabGroupEnum.ready,
    activeTabGroupTotal: 0,
    readyTabTotal: 0,
    pendingTabTotal: 0,
    inStockFilter: null as null | boolean,
    orderTypeFilter: null,
    orderTypeFilters: [],
    orders: [],
    skuFilterType: null,
    skuFilters: [],
    page: 1,
    perPage: 25,
    sort: null,
    startPickFromBarcode: [],
    total: 0
})

export default {
    state: defaultState(),
    getters: {
        getSortName(state: OrdersStateInterface): LocaleMessage | undefined | null {
            if (state.sort) {
                return getSorts().find((item: SortOptionType) => item.id === state.sort)?.name
            }
            return null
        },
        getFilterAndSortParams(
            state: OrdersStateInterface,
            _getters: OrdersGettersInterface,
            rootState: StateInterface
        ) {
            const params: GetAllocatedOrdersRequestParams = {
                filter_huboo_box: null,
                filter_work_group: null,
                filter_tab_group: state.activeTabGroup,
                filter_carrier: null,
                hub_id: rootState.core.hubId,
                page: null,
                filter_sku: [],
                filter_sku_type: null
            };
            if (state.customerFilter) {
                params.filter_huboo_box = state.customerFilter
            }
            if (state.orderTypeFilter) {
                params.filter_work_group = state.orderTypeFilter
            }
            if (state.courierFilter) {
                params.filter_carrier = state.courierFilter
            }
            if (state.sort) {
                const sort = getSorts().find(item => item.id === state.sort)
                if (sort && sort.type !== null) {
                    params[sort.type] = sort.value
                }
            }
            if (state.skuFilters.length) {
                params.filter_sku = state.skuFilters
            }
            if (state.skuFilterType) {
                params.filter_sku_type = state.skuFilterType.toLowerCase()
            }
            return params;
        }
    },
    mutations: {
        setPage(state: OrdersStateInterface, page: number): void {
            state.page = page
        },
        setPerPage(state: OrdersStateInterface, perPage: number): void {
            state.perPage = perPage
        },
        setOrders(state: OrdersStateInterface, orders: Array<object>): void {
            state.orders = orders.map(mapOrder)
        },
        setActiveTabGroupTotal(state: OrdersStateInterface, total: number): void {
            state.activeTabGroupTotal = total
        },
        setTotal(state: OrdersStateInterface, total: number): void {
            state.total = total
        },
        setCustomerFilters(state: OrdersStateInterface, filters: Array<object>): void {
            state.customerFilters = filters
        },
        setCourierFilters(state: OrdersStateInterface, filters: Array<object>): void {
            state.courierFilters = filters
        },
        setOrderTypeFilters(state: OrdersStateInterface, filters: Array<object>): void {
            state.orderTypeFilters = filters
        },
        setCustomerFilter(state: OrdersStateInterface, filter: string): void {
            state.customerFilter = filter
        },
        setCourierFilter(state: OrdersStateInterface, filter: string): void {
            state.courierFilter = filter
        },
        setOrderTypeFilter(state: OrdersStateInterface, filter: string): void {
            state.orderTypeFilter = filter
        },
        setSort(state: OrdersStateInterface, sort: string): void {
            state.sort = sort
        },
        resetState(state: OrdersStateInterface): void {
            setState(state, defaultState())
        },
        setStartPickFromBarcode(state: OrdersStateInterface, startPickFromBarcode: Array<number>): void {
            state.startPickFromBarcode = startPickFromBarcode
        },
        setTabGroup(state: OrdersStateInterface, activeTabGroup: TabGroupEnum): void {
            state.activeTabGroup = activeTabGroup
        },
        recalculateTabTotal(state: OrdersStateInterface): void {
            if (state.activeTabGroup === 'ready') {
                state.readyTabTotal = state.activeTabGroupTotal
                state.pendingTabTotal = state.total - state.activeTabGroupTotal
            } else {
                state.pendingTabTotal = state.activeTabGroupTotal
                state.readyTabTotal = state.total - state.activeTabGroupTotal
            }
        },
        setSkuFilters (state: OrdersStateInterface, filter: string[]) {
            state.skuFilters = filter
        },
        setSkuFilterType (state: OrdersStateInterface, filter: string) {
            state.skuFilterType = filter
        }
    },
    actions: {
        async startNextOrder(
            {
                getters,
                dispatch,
                rootState
            }: ActionContext<OrdersStateInterface, StateInterface>
        ): Promise<void> {
            if (!rootState.core.hubId) {
                return
            }
            const params = JSON.parse(JSON.stringify(getters.getFilterAndSortParams));
            params.filter_tab_group = TabGroupEnum.ready;
            try {
                const results = await api('nextOrder', {params: params, handleError: false})
                dispatch('core/redirect', {name: 'order', params: {orderId: results.data.id}}, {root: true})
            } catch (e) {
                // This is disgusting, cannot come up with a nice way to do this check
                if (axios.isAxiosError(e) && (<AxiosError>e).response?.status === 404) {
                    dispatch('core/addNotification', {
                        type: NotificationTypes.NOTIFICATION_TYPE_ERROR,
                        message: i18n.t('packing.all_remaining_orders_in_progress'),
                        timeout: 5000
                    }, {root: true})
                } else {
                    dispatch('core/handleError', e, {root: true});
                }
                dispatch('core/redirect', {name: 'orders'}, {root: true})
            }
        },
        async getOrders(
            {state, commit, dispatch, getters, rootState}: ActionContext<OrdersStateInterface, StateInterface>
        ): Promise<void> {
            if (!rootState.core.hubId) {
                return
            }
            try {
                const params = JSON.parse(JSON.stringify(getters.getFilterAndSortParams));
                params.page = state.page;
                const results = await api('allocatedOrders', {params: params})
                commit('setOrders', results.data.data)
                commit('setActiveTabGroupTotal', results.data.meta.total)
                commit('setPerPage', results.data.meta.per_page)
            } catch (e) {
                if (axios.isAxiosError(e) && (<AxiosError>e).response?.status === 404) {
                    dispatch('core/addNotification', {
                        type: NotificationTypes.NOTIFICATION_TYPE_ERROR,
                        message: i18n.t('packing.all_remaining_orders_in_progress'),
                        timeout: 5000
                    }, {root: true})
                } else {
                    dispatch('core/handleError', e, {root: true});
                }
                dispatch('core/redirect', {name: 'orders'}, {root: true})
            }
        },
        async getOrdersOverview(
            {commit, getters}: ActionContext<OrdersStateInterface, StateInterface>
        ): Promise<void> {
            const params = JSON.parse(JSON.stringify(getters.getFilterAndSortParams));
            params.filter_tab_group = null
            const results = await api('ordersOverview', {params: params})
            commit('setTotal', results.data.total_orders)
            commit('setStartPickFromBarcode', results.data.start_pick_from_barcode)
        },
        async getOrdersFilters(
            {commit, rootState}: ActionContext<OrdersStateInterface, StateInterface>
        ): Promise<void> {
            const results = await api(
                'ordersFilters',
                {
                    params: {
                        hub_id: rootState.core.hubId
                    }
                }
            )
            commit('setCustomerFilters', results.data.huboo_box)
            commit('setCourierFilters', results.data.carrier)
            commit('setOrderTypeFilters', results.data.work_group)
        },
        async getOrderByPackageIdentifier(
          {rootState, dispatch}: ActionContext<OrdersStateInterface, StateInterface>,
          scan: string
        ): Promise<void> {
            try {
                const { data: order } = await api(
                  'getOrderByPackageIdentifier',
                  {
                      params: {
                          hub_id: rootState.core.hubId,
                          package_identifier: scan
                      },
                      handleError: false
                  }
                )
                dispatch('core/redirect', {name: 'order', params: {orderId: order.id}}, {root: true})
            } catch (e) {
                let errorParams = e
                if (axios.isAxiosError(e) && (<AxiosError>e).response?.status === 404) {
                    errorParams = {
                        message: i18n.t('order_not_found_by_pi', {'package_identifier': scan})
                    }
                }
                dispatch('core/handleError', errorParams, {root: true});
            }
        }
    },
    namespaced: true,
}
