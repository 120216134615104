import HubooLocalisation from '@/localisation'
import axios from 'axios'
import App from '@/App.vue'
import scanner from "@/utilities/BarcodeScanner"
import IdleVue from 'idle-vue'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import router from '@/bootstrap/router'
import store from '@/bootstrap/store'
import vuetify from '@/plugins/vuetify'
import {
  SESSION_ACTIVE_EVENTS,
  SESSION_TIMEOUT,
  TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN
} from '@/bootstrap/config'
import {bus} from '@/bootstrap/bus'
import registerServiceWorker from "@/utilities/registerServiceWorker";
import VueCookies from 'vue-cookies'
import i18n from '@/plugins/i18n'
import DataDogLogService from '@/utilities/DataDogLogService'
import DataDogRumService from '@/utilities/DataDogRumService'
import { ScannerLatencyResolver } from "@/utilities/ScannerLatencyResolver"
import PortalVue from "portal-vue"
import registerPendo from '@/utilities/registerPendo'

DataDogLogService.init()
DataDogRumService.init()
registerServiceWorker()
registerPendo()

Vue.use(VueCookies)
Vue.use(VueI18n)
Vue.use(HubooLocalisation, {
  domainUrl: TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN,
  axios,
  i18n,
});
Vue.use(PortalVue)

// set default cookie config
Vue.$cookies.config('999d')

scanner.initialize(
  new ScannerLatencyResolver(Vue.$cookies.get('device')).resolve()
)

Vue.use(IdleVue, {
    idleTime: SESSION_TIMEOUT,
    events: SESSION_ACTIVE_EVENTS,
    eventEmitter: bus,
    store
})

let app;

Vue.prototype.$localise.handleLocaleChange()
    .then(() => {
        app = new Vue({
            el: '#app',
            i18n,
            router,
            store,
            vuetify,
            render: h => h(App)
        })
    });

export default app
