import { datadogLogs } from '@datadog/browser-logs'
import { DATADOG_LOG_CLIENT_TOKEN, ENABLE_DATADOG_LOG, STAGE } from '@/bootstrap/config'
import { version } from '@/../package'

class DataDogLogService {
    applicationName = 'perfect-picking'
    init() {
        if (!ENABLE_DATADOG_LOG) {
            return
        }
        datadogLogs.init({
            clientToken: DATADOG_LOG_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            env: STAGE,
            service: this.applicationName,
            forwardErrorsToLogs: false,
            sampleRate: 100,
            version
        })
    }
}

export default new DataDogLogService()
