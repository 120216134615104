import { ActionContext, Dispatch, Commit } from "vuex";
import { StateInterface } from "@/bootstrap/store";
import { OrderItem } from "@/types/OrderItem";
import { Stock } from "@/types/Stock";
import { ContainerPickState, ContainerScanValidationResult } from ".";
import actions from "./actionTypes";
import mutations from "./mutationTypes";
import { Order } from "@/types/Order";
import { ContainerType } from "../../types/ContainerType";
import { fetchContainerStock } from "./fetchContainerStock";
import { unique } from "@/utilities/arrayUtils";

type QuantityBySku = { sku: string; remainingQuantity: number };

export type ContainerPickActionContext = ActionContext<
  ContainerPickState,
  StateInterface
>;

export default {
  async [actions.ON_CONTAINER_SCAN](
    { dispatch, commit, getters }: ContainerPickActionContext,
    { containerLabel }: { containerLabel: string }
  ) {
    const containerStock = await fetchContainerStock(containerLabel);

    const containerScanValidation = await dispatch(
      actions.VALIDATE_CONTAINER_STOCK,
      containerStock
    );

    commit(mutations.SET_CONTAINER_SCAN_VALIDATION, containerScanValidation);

    if (!getters["isContainerScanValid"]) return;

    dispatch(actions.ASSIGN_STOCK_TO_TASKS, containerStock);
  },

  async [actions.VALIDATE_CONTAINER_STOCK](
    { dispatch }: ContainerPickActionContext,
    containerStock: Stock[]
  ): Promise<ContainerPickState["containerScanValidation"]> {
    const containerTypeValidation = await dispatch(
      actions.VALIDATE_CONTAINER_TYPE,
      containerStock
    );

    const containerParentValidation = await dispatch(
      actions.VALIDATE_CONTAINER_PARENT,
      containerStock
    );

    const containerStatusValidation = await dispatch(
      actions.VALIDATE_CONTAINER_STATUS,
      containerStock
    );

    const containerSkusInOrderValidation = await dispatch(
      actions.VALIDATE_CONTAINER_ITEMS_IN_ORDER,
      containerStock
    );

    const previouslyPickedValidation = await dispatch(
      actions.VALIDATE_CONTAINER_NOT_PREVIOUSLY_SCANNED,
      containerStock
    );

    // If stock has previously been picked then don't check quantity, it's implicit the quantity is invalid if already picked
    const quantityValidation = previouslyPickedValidation.failed
      ? { failed: false }
      : await dispatch(actions.VALIDATE_CONTAINER_QUANTITY, containerStock);

    const containerHasBatchValidation = await dispatch(
      actions.VALIDATE_CONTAINER_HAS_BATCH,
      containerStock
    );

    const batchMinExpiryValidation = await dispatch(
      actions.VALIDATE_CONTAINER_BATCH_EXPIRY_MEETS_MIN_FOR_ORDER,
      containerStock
    );

    const batchExpiryValidation = await dispatch(
      actions.VALIDATE_CONTAINER_BATCH_EXPIRY_IS_IN_FUTURE,
      containerStock
    );

    const batchQuarantineValidation = await dispatch(
      actions.VALIDATE_CONTAINER_QUARANTINE_STATUS,
      containerStock
    );

    const unpickableLocationValidation = await dispatch(
      actions.VALIDATE_PICKABLE_LOCATION,
      containerStock
    );

    return {
      containerTypeValidation,
      containerParentValidation,
      containerStatusValidation,
      containerSkusInOrderValidation,
      quantityValidation,
      batchExpiryValidation,
      batchMinExpiryValidation,
      batchQuarantineValidation,
      previouslyPickedValidation,
      unpickableLocationValidation,
      containerHasBatchValidation,
    };
  },

  [actions.VALIDATE_CONTAINER_QUANTITY](
    { rootState }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const containerStockTotalsBySku = stock.reduce((prev, cur) => {
      const hubooSku = cur.huboo_sku.toString();
      const existingValue = prev[hubooSku] ?? 0;
      prev[hubooSku] = existingValue + cur.quantity;
      return prev;
    }, {} as Record<string, number>);

    const tasksRemaining = getRemainingQuantitiesGroupedBySku(
      rootState.picking.order.items
    );
    const isValid = Object.entries(containerStockTotalsBySku).every(
      ([sku, containerQuantity]) => {
        const task = tasksRemaining.find((skuTotal) => skuTotal.sku === sku);

        return !task || containerQuantity <= task.remainingQuantity;
      }
    );

    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_TYPE](
    _: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const validContainerTypes: ContainerType[] = ["box", "pallet"];
    const isValid = stock.every(
      (container) =>
        container.container &&
        validContainerTypes.includes(container.container.container_type)
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_PARENT](
    _: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every(
      (container) => container.container?.parent_id === null
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_STATUS](
    _: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every(
      (container) => container.container?.status === 1
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_ITEMS_IN_ORDER](
    { rootState }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every(
      (container) =>
        getOrderItemsForSku(rootState, container.huboo_sku).length > 0
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_QUARANTINE_STATUS](
    _: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every(
      (item) => !item.batch || item.batch?.recall_status === "AVAILABLE"
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_BATCH_EXPIRY_MEETS_MIN_FOR_ORDER](
    { rootState }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every((item) => {
      const batchExpiryDate = item.batch?.bbe_date;
      if (!batchExpiryDate) return true;

      const tasks = getOrderItemsForSku(rootState, item.huboo_sku);
      return tasks.every(
        (task) =>
          !task.batch_min_expiry_date ||
          task.batch_min_expiry_date <= batchExpiryDate
      );
    });
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_BATCH_EXPIRY_IS_IN_FUTURE](
    _: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every((item) => {
      const batchExpiryDate = item.batch?.bbe_date;
      return !batchExpiryDate || batchExpiryDate > new Date(Date.now());
    });
    return { failed: !isValid };
  },

  [actions.VALIDATE_CONTAINER_HAS_BATCH](
    { rootState }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const stockMissingBatchInfo = stock.filter((item) => {
      const tasks = getOrderItemsForSku(rootState, item.huboo_sku);
      const requiresBatchData = tasks.some((task) => task.is_batched);

      if (!requiresBatchData) return false;

      return !item.batch;
    });
    const hasFailed = stockMissingBatchInfo.length > 0;

    return {
      failed: hasFailed,
      content: {
        skus: unique(stockMissingBatchInfo.map((item) => item.huboo_sku)).join(
          ", "
        ),
      },
    };
  },

  [actions.VALIDATE_CONTAINER_NOT_PREVIOUSLY_SCANNED](
    { getters }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const isValid = stock.every(
      ({ id }) => !getters.pickedStockIds.includes(id)
    );
    return { failed: !isValid };
  },

  [actions.VALIDATE_PICKABLE_LOCATION](
    { rootState }: ContainerPickActionContext,
    stock: Stock[]
  ): ContainerScanValidationResult {
    const order: Order = rootState.picking.order;
    const pickableLocations = order.pickable_locations;
    const scannedLocation = stock[0].location;
    const isPickableLocation = pickableLocations.find(
      (location) => location.id === scannedLocation?.location_id
    );
    const unpickableLocation =
      !isPickableLocation && scannedLocation?.location_name
        ? scannedLocation.location_name
        : "";

    return {
      failed: !isPickableLocation,
      content: {
        pickableLocations: pickableLocations
          .map((location) => location.name)
          .join(", "),
        unpickableLocation,
      },
    };
  },

  [actions.ASSIGN_STOCK_TO_TASKS](
    { rootState, dispatch, commit }: ContainerPickActionContext,
    stock: Stock[]
  ): void {
    for (const stockItem of stock) {
      assignStockItemToTask(rootState, stockItem, dispatch, commit);
    }
  },
};

const getOrderItemsForSku = (
  rootState: StateInterface,
  sku: number
): OrderItem[] =>
  rootState.picking.order.items.filter(
    (item: OrderItem) => item.sku === sku.toString()
  );

const getRemainingQuantitiesGroupedBySku = (
  itemsPicked: OrderItem[]
): QuantityBySku[] =>
  itemsPicked.reduce((prev, cur) => {
    const remainingQuantity = cur.quantity - cur.quantityPicked;
    const existingOrderItem = prev.find((item) => item.sku === cur.sku);
    if (!existingOrderItem) {
      prev.push({ sku: cur.sku, remainingQuantity });
    } else {
      existingOrderItem.remainingQuantity += remainingQuantity;
    }

    return prev;
  }, [] as QuantityBySku[]);

const assignStockItemToTask = (
  rootState: StateInterface,
  stock: Stock,
  dispatch: Dispatch,
  commit: Commit
) => {
  const tasksForSku: OrderItem[] = rootState.picking.order.items.filter(
    (task: OrderItem) => task.sku === stock.huboo_sku.toString()
  );

  let quantityAssigned = 0;
  for (
    let i = 0;
    quantityAssigned < stock.quantity && i < tasksForSku.length;
    i++
  ) {
    const task = tasksForSku[i];
    const taskRemainingQuantity = task.quantity - task.quantityPicked;
    const itemRemainingQuantity = stock.quantity - quantityAssigned;
    const quantityToAssign =
      itemRemainingQuantity > taskRemainingQuantity
        ? taskRemainingQuantity
        : itemRemainingQuantity;

    dispatch(
      "picking/increaseTaskPickedQuantity",
      {
        taskId: task.id,
        quantity: quantityToAssign,
      },
      { root: true }
    );

    commit(mutations.PICK_CONTAINER_STOCK, {stock: stock, orderLineId: task.orderLineId});

    quantityAssigned += quantityToAssign;

    if (stock.batch) {
      commit(
        "picking/storeBatch",
        {
          itemId: task.id,
          batchId: stock.batch.batch_id,
          quantity: quantityToAssign,
        },
        { root: true }
      );
    }
  }
};
