<template>
  <v-app v-bind:style="{zoom: zoom}">
    <v-app-bar app :color="appBarColor" dark>
      <portal-target name="app-bar-navigation" class="app-bar-navigation">
        <v-app-bar-nav-icon v-if="navigationEnabled" @click.stop="drawer = !drawer"/>
      </portal-target>
      <img class="mr-3" :src="require('./assets/logo-os.svg')" height="30"/>
      <v-toolbar-title class="pl-0">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <portal-target name="app-bar-right"/>
    </v-app-bar>
    <v-navigation-drawer v-if="navigationEnabled" v-model="drawer" app left temporary height="100%">
      <navigation/>
      <template v-slot:append>
        <v-list-item-group active-class="primary--text">
          <v-list-item disabled>
            <v-list-item-content>
              <v-list-item-title id="version">
                {{ `${$t('version')} ${version}` }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-navigation-drawer>
    <notifications-bar/>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <haptic-feedback
        v-if="hapticFeedbackEnabled"
        :config="hapticFeedbackConfig"
    />
  </v-app>
</template>

<script>
import Navigation from '@/components/navigation/Default'
import NotificationsBar from '@/components/generic/NotificationsBar'
import auditLog from "@/bootstrap/auditLog";
import {isHidden, getVisibilityEvent} from "@/utilities/global";
import {PIONEER_STAGE, SESSION_ACTIVE_EVENTS, STAGE} from "@/bootstrap/config";
import {version} from '@/../package'
import HapticFeedback from "@/components/generic/HapticFeedback";
import {HAPTIC_FEEDBACK_ENABLED} from "@/bootstrap/config";
import {getHapticFeedbackConfig} from "@/bootstrap/HapticFeedbackConfig";

export default {
  name: 'App',
  components: {
    HapticFeedback,
    'navigation': Navigation,
    'notifications-bar': NotificationsBar
  },
  data: () => ({
    drawer: false,
    visibilityEvents: {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    },
    zoom: 1,
    version
  }),
  computed: {
    appBarColor () {
      if (STAGE === PIONEER_STAGE) {
        return 'grey darken-1'
      }
      return 'primary'
    },
    title () {
      return this.$store.getters['core/getTitle']
    },
    navigationEnabled () {
      return this.$store.getters['core/isAuthenticated']
    },
  },
  onIdle () {
    this.$store.dispatch('core/redirect', {name: 'logout'})
  },
  created () {
    this.hapticFeedbackEnabled = HAPTIC_FEEDBACK_ENABLED;
    this.hapticFeedbackConfig = getHapticFeedbackConfig();
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.addEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    this.checkActiveSession()
    if (getVisibilityEvent()) {
      document.addEventListener(getVisibilityEvent(), this.checkActiveSession)
    }
    window.addEventListener('beforeunload', this.commitAuditLog)
    if (this.$cookies) {
      this.zoom = this.$cookies.get('zoom');
      window.bus.$on('zoomChanged', (zoom) => {
        this.$cookies.set('zoom', zoom);
        this.zoom = zoom;
      });
    }
  },
  beforeDestroy () {
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.removeEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    document.removeEventListener(getVisibilityEvent(), this.checkActiveSession)
    window.removeEventListener('beforeunload', this.commitAuditLog);
  },
  methods: {
    setActiveSessionTime: function () {
      this.checkActiveSession()
      this.$store.commit('core/setActiveSessionTime');
    },
    commitAuditLog () {
      auditLog.commit()
    },
    checkActiveSession () {
      if (!isHidden()) {
        this.$store.dispatch('core/checkActiveSession')
      }
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    max-width: #{map-get($grid-breakpoints, 'md')} !important;
  }
}

.v-card__title {
  word-break: break-word !important;
}

// Override success button text to use dark color (light theme)
.v-btn:not(.v-btn--outlined).success {
  color: map-deep-get($material-theme, 'text', 'primary')
}

.v-application--is-ltr .v-toolbar__content > .app-bar-navigation > .v-btn.v-btn--icon:first-child,
.v-application--is-ltr .v-toolbar__extension > .app-bar-navigation > .v-btn.v-btn--icon:first-child {
  margin-left: -12px;
}
</style>
