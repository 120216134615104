export enum AuditLogEventTypes {
  EVENT_SIGN_IN = "sign in",
  EVENT_HUB_CHANGE = "hub change",
  EVENT_START_PICKING = "start picking",
  EVENT_MISPICK = "mispick",
  EVENT_BATCH_INVALID_SCAN = "batch invalid scan",
  EVENT_MANUAL_PICK = "manual pick",
  EVENT_PACKAGING_CHANGE = "packaging change",
  EVENT_ITEM_SCAN = "item scan",
  EVENT_FAIL_TO_FIND_ORDER = "failed to find order",
  EVENT_COMPLETE_PACKING = "packing complete",
  EVENT_START_PACKING = "packing started",
  EVENT_ORDER_IN_PROGRESS_RESPONSE = "order in progress response",
  EVENT_PI_LABEL_ASSOCIATION = "pi label associated with order",
  EVENT_ROUTE_CHANGE = "route change",
  EVENT_ABANDON_ORDER = "abandon order",
}
