
import Vue from "vue";
import {AbandonmentReasonsEnum, AbandonmentReasonsI18nMap} from "@/utilities/AbandonmentReasons";

export default Vue.extend({
  name: 'AbandonDialog',
  data: () => ({
    loading: false,
    abandonmentReasons: AbandonmentReasonsI18nMap
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    async abandon(reason: AbandonmentReasonsEnum | null): Promise<void> {
      this.loading = true;
      try {
        this.$emit('allowOrderExit')
        await this.$store.dispatch('picking/abandon', reason)
      } catch (e) {
        this.loading = false;
      }
    },
  }
})
