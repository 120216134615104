import lodash from 'lodash'

let environmentVariables = process.env

if (window.ENV || window.env) {
    environmentVariables = lodash.extend(environmentVariables, window.ENV || window.env)
}

const falseValues = [false, 0, 'false', 'False', 'FALSE', '0'];
for (const key in environmentVariables) {
    if (falseValues.indexOf(environmentVariables[key]) !== -1) {
        environmentVariables[key] = false
    }
}

export const SESSION_TIMEOUT = parseInt(environmentVariables.VUE_APP_SESSION_TIMEOUT)
export const API_BASE_URL = environmentVariables.VUE_APP_API_BASE_URL
export const API_CLIENT_ID = environmentVariables.VUE_APP_CLIENT_ID
export const AUDIT_LOG_ENABLED = !!environmentVariables.VUE_APP_AUDIT_LOG_ENABLED
export const TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN = environmentVariables.VUE_APP_TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN
export const STAGE = environmentVariables.VUE_APP_STAGE
export const ENABLE_PENDO = !!environmentVariables.VUE_APP_ENABLE_PENDO
export const PENDO_KEY = environmentVariables.VUE_APP_PENDO_KEY
export const VERSION = environmentVariables.VUE_APP_VERSION
export const ENABLE_DATADOG_LOG = !!environmentVariables.VUE_APP_ENABLE_DATADOG_LOG
export const DATADOG_LOG_CLIENT_TOKEN = environmentVariables.VUE_APP_DATADOG_LOG_CLIENT_TOKEN
export const ENABLE_DATADOG_RUM = !!environmentVariables.VUE_APP_ENABLE_DATADOG_RUM
export const DATADOG_RUM_CLIENT_TOKEN = environmentVariables.VUE_APP_DATADOG_RUM_CLIENT_TOKEN
export const DATADOG_RUM_APPLICATION_ID = environmentVariables.VUE_APP_DATADOG_RUM_APPLICATION_ID
export const PERFECT_PICKING_API_URL = environmentVariables.VUE_APP_PERFECT_PICKING_API_URL
export const PERFECT_PICKING_API_ENABLED_HUBS = environmentVariables.VUE_APP_PERFECT_PICKING_API_ENABLED_HUBS?.split(',')


export const PIONEER_STAGE = 'pioneer';

export const SESSION_ACTIVE_EVENTS = ['keydown', 'mousedown', 'touchend']

export const EMPTY_VARIATION = '&none'
export const ORDER_STEP_ID_PICK = 'pick'
export const ORDER_STEP_ID_INSERT = 'insert'
export const ORDER_STEP_ID_DISPATCH_NOTE = 'dispatch_note'
export const ORDER_STEP_ID_PACK = 'pack'
export const ORDER_STEP_ID_SPLITPACK = 'splitpack'
export const ORDER_STEP_ID_LABEL = 'label'
export const ORDER_STEP_ID_COMPLETE = 'complete'

export const STATUS_ALLOCATED = 'Allocated'
export const STATUS_IN_PROGRESS = 'In Progress'

export const ORDER_TYPE_FILTER_SINGLES = 'SINGLE'
export const ORDER_TYPE_FILTER_PRE_PACKED = 'PRE-PACKED'
export const ORDER_TYPE_FILTER_MULTI = 'MULTI'

export const REDIRECT_TO_ORDERS_ON_PICK_COMPLETE = 1;
export const REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE = 2;
export const REDIRECTION_STRATEGIES = [REDIRECT_TO_ORDERS_ON_PICK_COMPLETE, REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE];

export const HAPTIC_FEEDBACK_ENABLED = true;
export const NOTIFICATIONS_DEFAULT_TIMEOUT = 5000;
