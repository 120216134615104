import { Batch } from "@/types/Batch";
import { Container } from "@/types/Container";
import { Location } from "@/types/Location";
import { StockAttributes, StockRelationships, Stock, BatchEntity, ContainerEntity, LocationEntity } from "@/types/Stock";
import { paginateJsonApiRequest } from "@/utilities/jsonApiPaginator";

export const fetchContainerStock = async (containerLabel: string, params = {}): Promise<Stock[]> => {
    const responses = await paginateJsonApiRequest<
      true,
      StockAttributes,
      StockRelationships
    >(
      "containerStock",
      {
        inlineParams: {
          labelContent: containerLabel,
        },
        params: {
          include: ["batch", "container", "location"].join(","),
          ...params,
        },
        headers: {
          Accept: "application/vnd.api+json",
        },
      },
      100
    );

    const included = responses.flatMap((response) => response.data.included);
    const includedBatches = included.filter(
      (include) => include?.type === "batch"
    ) as BatchEntity[];
    const includedContainers = included.filter(
      (include) => include?.type === "container"
    ) as ContainerEntity[];
    const includedLocations = included.filter(
      (include) => include?.type === "location"
    ) as LocationEntity[];
  
    return responses
      .flatMap((response) => response.data.data)
      .map(({ id, attributes, relationships }) => {
        const batch = includedBatches?.find(
          (include) =>
            relationships?.batch && include.id === relationships?.batch.data?.id
        );
  
        const container = includedContainers?.find(
          (include) =>
            relationships?.container &&
            include.id === relationships?.container.data?.id
        );
  
        const location = includedLocations?.find(
          (include) =>
            relationships?.location &&
            include.id === relationships?.location.data?.id
        );
  
        const batchInfo: Batch | undefined = batch
          ? {
              batch_id: batch.id,
              huboo_sku: batch.attributes.huboo_sku,
              bbe_date: batch.attributes.bbe_date
                ? new Date(batch.attributes.bbe_date)
                : undefined,
              recall_status: batch.attributes.recall_status,
              quantity: batch.attributes.quantity,
            }
          : undefined;
  
        const containerInfo: Container | undefined = container
          ? { ...container?.attributes }
          : undefined;
  
        const locationInfo: Location | undefined = location
          ? { ...location?.attributes }
          : undefined;
  
        return {
          ...attributes,
          ...(batchInfo && { batch: batchInfo }),
          ...(containerInfo && { container: containerInfo }),
          ...(locationInfo && { location: locationInfo }),
          ...{ id: id.toString() },
        };
      });
  };
