
import {
  ORDER_TYPE_FILTER_PRE_PACKED,
  ORDER_TYPE_FILTER_SINGLES,
} from '@/bootstrap/config'
import {
  SORT_CUSTOMER,
  SORT_LOCATION,
  SORT_ORDER_TYPE,
  SORT_PRODUCT,
  getSorts,
} from '@/utilities/ordersSortOptions'
import {defineComponent} from 'vue'
import {TranslateResult} from 'vue-i18n'

enum SkuFilterTypes {
  INCLUDES = 'includes',
  EXCLUDES = 'excludes',
  ONLY = 'only'
}

export default defineComponent({
  data: () => ({
    loadingFilters: true,
    skuFilters: [] as string[],
    skuFilterType: null,
    customerFilter: null,
    orderTypeFilter: null,
    courierFilter: null,
    sort: null,
  }),
  created () {
    this.getFilters()
    this.skuFilters = this.$store.state.orders.skuFilters
    this.skuFilterType = this.$store.state.orders.skuFilterType
    this.customerFilter = this.$store.state.orders.customerFilter
    this.orderTypeFilter = this.$store.state.orders.orderTypeFilter
    this.courierFilter = this.$store.state.orders.courierFilter
    this.sort = this.$store.state.orders.sort
  },
  computed: {
    skuFilterTypes (): {name: TranslateResult|string, value: SkuFilterTypes | null}[] {
      return [
        {
          name: this.$t('all_skus'),
          value: null,
        },
        {
          name: this.$t('sku-filter-includes'),
          value: SkuFilterTypes.INCLUDES
        },
        {
          name: this.$t('sku-filter-excludes'),
          value: SkuFilterTypes.EXCLUDES
        },
        {
          name: this.$t('sku-filter-only'),
          value: SkuFilterTypes.ONLY
        }
      ]
    },
    sorts () {
      let filteredSorts = getSorts()
      if (this.orderTypeFilter !== null) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_ORDER_TYPE)
      }
      if (this.customerFilter !== null) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_CUSTOMER)
      }
      if (this.orderTypeFilter !== ORDER_TYPE_FILTER_SINGLES) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_PRODUCT)
      }
      if (
          this.orderTypeFilter !== ORDER_TYPE_FILTER_SINGLES &&
          this.orderTypeFilter !== ORDER_TYPE_FILTER_PRE_PACKED
      ) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_LOCATION)
      }
      return filteredSorts
    },
    customers () {
      const filters = this.$store.state.orders.customerFilters
      filters.unshift({id: null, name: this.$t('all_customers')})
      return filters
    },
    orderTypes () {
      const filters = this.$store.state.orders.orderTypeFilters
      filters.unshift({id: null, name: this.$t('all_order_types')})
      return filters
    },
    couriers () {
      const filters = this.$store.state.orders.courierFilters
      filters.unshift({id: null, name: this.$t('all_couriers')})
      return filters
    },
    canSubmit(): boolean {
      return this.skuFilterIsValid
    },
    skuFilterIsValid(): boolean {
      return !!(!this.skuFilterType || (this.skuFilters.length && this.validateSku(this.skuFilters) === true))
    }
  },
  methods: {
    validateSku(skus: string[]): boolean|TranslateResult {
      if (!skus.length) {
        return true
      }
      const skuValidation = skus.every(sku => sku.toString().match(/^[0-9]*$/))
      return skuValidation || this.$t('filter_sku_validation')
    },
    async getFilters () {
      this.loadingFilters = true
      await this.$store.dispatch('orders/getOrdersFilters')
      this.loadingFilters = false
    },
    clear () {
      this.skuFilterType = null
      this.skuFilters = []
      this.customerFilter = null
      this.orderTypeFilter = null
      this.courierFilter = null
      this.sort = null
    },
    validateCurrentSort () {
      if (!this.sorts.find(item => item.id === this.sort)) {
        this.sort = null
      }
    },
    applyFilters () {
      this.$store.commit('orders/setSkuFilters', this.skuFilters)
      this.$store.commit('orders/setSkuFilterType', this.skuFilterType)
      this.$store.commit('orders/setCustomerFilter', this.customerFilter)
      this.$store.commit('orders/setCourierFilter', this.courierFilter)
      this.$store.commit('orders/setOrderTypeFilter', this.orderTypeFilter)
      this.validateCurrentSort()
      this.$store.commit('orders/setSort', this.sort)
      this.$store.commit('orders/setPage', 1)
      this.$emit('change')
      this.$emit('close')
    },
    changeSkuTypeFilter (e: SkuFilterTypes | null) {
      if (e === null) {
        this.skuFilters = []
      }
    }
  }
})
